import { round } from '@/utils/Numbers';

/**
 * Apply mask to the given amount
 * @param {string | number} value
 * @param {Object} props
 * @returns {string} Masked amount
 */
export const mask = function (value = 0, props = {}) {
    const { precision = 2, prefix = '', suffix = '', decimal = '.', delimeter = ',' } = props;
    const negative = String(value).indexOf('-') === -1 ? '' : '-';
    const absValue = Math.abs(value);
    const floatValue = absValue.toFixed(precision);
    const [integerPart, decimalPart] = splitIntegerAndDecimal(floatValue);
    const delimetedIntegerPart = getDelimetedValue(integerPart, delimeter);

    return `${negative}${prefix}${joinIntegerAndDecimal(delimetedIntegerPart, decimalPart, decimal)}${suffix}`;
};

export const splitIntegerAndDecimal = function (floatValue) {
    return String(floatValue).split('.');
};

/**
 * Format integer with delimeted thousands
 * @param {string} integer
 * @param {string} delimeter
 * @returns {string} Delimeted integer
 */
export const getDelimetedValue = function (integer, delimeter) {
    return integer.replace(/(\d)(?=(?:(\d{3}))+\b)/gm, `$1${delimeter}`);
};

export const getNegative = function (maskedValue) {
    return maskedValue.indexOf('-') === -1 ? 1 : -1;
};

/**
 * Convert digits string to the value according to the given precision
 * @param {string} digits
 * @param {number} precision
 * @returns {number} Precised amount
 */
export const convertToCurrency = function (digits, precision) {
    const divider = Math.pow(10, precision);
    const result = parseFloat(digits) / divider;

    return round(result, precision);
};

export const getOnlyDigits = function (maskedValue) {
    return maskedValue.replace(/\D+/g, '');
};

export const joinIntegerAndDecimal = function (integer, decimal, delimeter) {
    return decimal ? integer + delimeter + decimal : integer;
};

export const formatQuotedRentAmount = amount => {
    return amount && typeof amount === 'string' ? Number(amount.replace('$', '')) : amount;
};
